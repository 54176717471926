<template>
	<div class="rc-animated__number">
		<!-- <button @click='start'>probar1</button> -->
		<div class="titulo__number">
			<div v-if="preTitulo" class="titulo_pre">
				{{preTitulo}}
			</div>
			<div class='number__slot' v-for='(numero, key) in numValor' :ref='referencia' :key="key"> 
				<div v-if="numero == '.' || numero == ','" class='number__window'> 
					<div class='number__wrap'> 
						<div class='number__item' v-for='(opt, key) in numerosDot' :key="key">{{ opt }}</div>
						<div class='number__item number__item--copy' >{{ numerosDot[0] }}</div>
					</div>	
				</div>
				<div v-else class='number__window'> 
					<div class='number__wrap'> 
						<div class='number__item' v-for='(opt, key) in numerosPanel' :key="key">{{ opt }}</div>
						<div class='number__item number__item--copy' >{{ numerosPanel[0] }}</div>
					</div>
				</div>
			</div>
			<div v-if="posTitulo" class="titulo_pos">
				{{posTitulo}}
			</div>
		</div>
		<div v-if="subTitulo" class="subtitulo__number" v-html="subTitulo">
		</div>
	</div>
</template>

<script>
	import { Component, Vue } from "vue-property-decorator";
	
	const next = window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				window.msRequestAnimationFrame ||
				window.oRequestAnimationFrame ||
				function(cb) { window.setTimeout(cb, 1000/60) }

	@Component({
		props: {
			preTitulo : String,
			posTitulo : String,
			subTitulo : String,
			numValor : String,
			referencia : String,
			animar : Boolean
        },
		data() {
			return {
				numerosPanel: ['0','1','2','3','4','5','6','7','8','9'],
				numerosDot: ['.',','],
				opts: null,
				startedAt: null,
			};
		},
		methods: {
			start: function() {
				if (this.$data.opts) {
      				return
      			}
    	  		let arrNum = [...this.$props.numValor];
      			this.$data.opts = arrNum.map( (numSelect, i) => {
					const slot = this.$refs[`${this.$props.referencia}`][i];
					//const choice = Math.floor( Math.random() * this.$data.numerosPanel.length )
					const choice = numSelect;
					let indexChoice = this.$data.numerosPanel.indexOf(choice);
					let indexChoice2 = this.$data.numerosDot.indexOf(choice);
					const opts = {
						el: slot.querySelector('.number__wrap'),
						finalPos: indexChoice != -1 ? indexChoice * 80 * -1 : indexChoice2 * 80 * -1,
						startOffset: 1000 + Math.random() * 500 + i * 500 * -1,
						height: indexChoice != -1 ? this.$data.numerosPanel.length * 80 * -1 : this.$data.numerosDot.length * 80 * -1,
						duration: 3000 + i * 700, // milisegundos
						isFinished: false,
					}
					// Posicion inicial opts
					return opts
	      		})
      			//this.animate()
      			//next( this.animate )
				next( this.animate )
    		},
			animate: function(timestamp) {
				//console.log('FUNCION ANIMACION!');
				if (this.$data.startedAt == null) {
      				this.$data.startedAt = timestamp
      			}
      
      			const timeDiff = timestamp - this.$data.startedAt
      
      			this.$data.opts.forEach( opt => {
					//console.log('este es el OPT ', opt)
					if (opt.isFinished) {
						return
					}
				
					const timeRemaining = Math.max(opt.duration - timeDiff, 0)
					const power = 3
					const offset = ( Math.pow(timeRemaining, power) / Math.pow(opt.duration, power) ) * opt.startOffset
					// negative, such that slots move from top to bottom
					const pos = 1 * Math.floor((offset + opt.finalPos) % opt.height)
					opt.el.style.transform = "translateY(" + pos + "px)"
			
					if ( timeDiff > opt.duration ) {
						opt.isFinished = true
					}
				})
      
				if (this.$data.opts.every( o => o.isFinished )) {
					this.$data.opts = null
					this.$data.startedAt = null
				} else {
					next( this.animate )
					
				}
			}
		},
		watch: {
			animar(v) {
				if(v){
					// @ts-ignore
					this.start();
				}
			},
		}
	})
	export default class NumbersPanel extends Vue {}
</script>

<style lang="scss" scoped>
	.rc-animated__number{
		height: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 2rem;
		.titulo__number{
			display: flex;
    		flex-flow: row;
			align-items: baseline;
			.titulo_pre, .titulo_pos {
				font-size: 2.5rem;
				font-weight: 500;
				background: $rc-prim-sunny;
				background-clip: text;
				-webkit-text-fill-color: transparent;
				color: transparent;
				margin: 0;
				padding: 0;
				height: 2.5rem;
				margin-block-start: 0;
				margin-block-end: 0;
				display: flex;
				align-items: center;
			}
			.titulo_pre{
				margin-right: 10px;
			}
			.titulo_pos{
				margin-left: 10px;
			}
		}
		.subtitulo__number{
			font-size: 1.5rem;
			color: $rc-prim-g1;
			text-align: center;
		}
	}
	
	.number__slot{
		float: left;
	}
	.number__window{
		height: 5rem;
		overflow: hidden;
	}
	.number__item{
		height: 5rem;
		font-size: 5rem;
		display: flex;
    	align-items: center;
		font-size: 5rem;
		font-weight: 700;
		background: $rc-prim-sunny;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		color: transparent;
	}
	// Breakpoints 
	@each $breakpoint in $breakpoints_screen_text{
		@include breakpoint_media_queries($breakpoint){
			@if $breakpoint == "x-large"{
				
			}@else if $breakpoint == "large"{
				
			}@else if $breakpoint == "medium-to-large"{
				
			}@else if $breakpoint == "small-to-medium"{
				.titulo_pre{
					font-size: 1.5rem !important;
				}
				.titulo_pos{
					font-size: 1.5rem !important;
				}
				.number__item{
					height: 5rem;
					font-size: 3rem;
					display: flex;
					align-items: center;
					font-weight: 700;
					background: $rc-prim-sunny;
					background-clip: text;
					-webkit-text-fill-color: transparent;
					color: transparent;
				}
				.titulo_pre, .titulo_pos {
					font-size: 1.5rem;
				}
				.subtitulo__number{
					font-size: 0.9rem !important;
				}
			}@else if $breakpoint == "small"{
				.titulo_pre, .titulo_pos {
					font-size: 1.2rem!important;
					font-weight: 400;
				}
				.number__item{
					height: 5rem;
					font-size: 3rem;
					display: flex;
					align-items: center;
					font-weight: 700;
					background: $rc-prim-sunny;
					background-clip: text;
					-webkit-text-fill-color: transparent;
					color: transparent;
				}
				.subtitulo__number{
					font-size: 1rem !important;
				}
			}
		}
	}
</style>
